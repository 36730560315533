import React, { Fragment, useEffect, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'

import { graphql, Link } from 'gatsby'

import {
  useWindowSize,
  useWindowWidth,
  useWindowHeight,
} from '@react-hook/window-size'

import SEO from '../components/seo'
import Layout from '../components/layout'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import styled from 'styled-components'
import ContactForm from '../components/ContactForm'

import ReactCompareImage from '../components/before-after-image'

import Breadcrumbs from '../components/breadcrumbs'

import '../styles/projects-gallery.scss'

import Carousel from 'react-images'
import Slider from 'react-slick'

import {
  SliderContainer,
  PrevArrow,
  NextArrow,
} from '../components/slider-components'
import { Maximize, X } from 'react-feather'
import Button from '../components/atoms/Button'


const BlogPostLayout = styled.div`
  background: #fff;
  .articleBody {
    max-width: 900px;
    margin: 0 auto;
    padding: 1em;
  }
`
const Header = styled.div`
  text-align: left;
  margin: 0 auto;
  margin-bottom: 1em;
  border-bottom: 1px solid #eaeaea;
  h1 {
    font-size: calc(1.15vw + 1.25em);
    line-height: 1.15;
    font-weight: 800;
    color: #000;
  }
  border-bottom: 1px solid #f1f1f1;
  background: #f7f7f7;
`

const BlogPostImage = styled.div`
  img {
    max-width: 900px;
    margn: 0 auto;
    text-align: center;
    border-radius: 0;
    margin-bottom: 1em;
    box-shadow: none !important;
  }
`
const ShareThisPost = styled.div`
  p {
    font-weight: bold;
    margin: 0;
  }
  display: flex;
  align-items: center;
  .SocialMediaShareButton {
    display: flex;
    padding: 0 9px;
  }
  svg {
    stroke: none;
    fill: #888;
    cursor: pointer;
  }
  .stroke-white {
    stroke: white;
  }
`

const ImageGalleryImage = ({
  fluid,
  fullScreen,
  i,
  setMaxHeight,
  maxHeight,
  windowHeight,
  windowWidth,
  isFullScreen,
  pagePath,
  toggleLightbox,
  paddingTop,
  activeImageState,
  ...otherprops
}) => {
  const gatsbyImgRef = useRef()

  return (
    <div
      className="aspect-ratio-box projects-project shadow rounded-xl overflow-hidden pointer-cursor cursor-pointer"
      ref={gatsbyImgRef}
      onClick={() => toggleLightbox(i)}
      style={{
        paddingTop,
        display:
          i >= activeImageState - 1 && i <= activeImageState + 1
            ? 'block'
            : 'none',
      }} //lock aspect ratio with padding; no jumping because content size is provided and aspect ratio is computed for each page; lazy load images 3 images inView and images before and after
    >
      <GatsbyImage
        className="image-gallery-image overflow-hidden mb-0 aspect-ratio-box-inside "
        image={getImage(otherprops)}
        // onLoad={e =>
        //   updateActiveImageLoadingState(
        //     activeImageLoadingState.map(([index, state]) => {
        //       if (index == i) return [i, true]
        //       return [index, state]
        //     })
        //   )
        // }
        alt=""
      />
    </div>
  )
}

const settings = {
  accessibility: true,
  dots: true,
  infinite: true,
  // autoplay: true,
  autoplaySpeed: 7000,
  pauseOnHover: false,
  // speed: 300,
  slidesToShow: 1,
  slidesToScroll: 1,
  swipeToSlide: true,
  fade: false,
  cssEase: 'linear',
  className: 'disable-interaction-on-non-active relative',
  nextArrow: <PrevArrow />,
  prevArrow: <NextArrow />,
}

const SliderCarousel = (props) => {
  const { images, activeImageState } = props
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [isFullScreen, setFullScreen] = useState(false)
  const [maxHeight, setMaxHeight] = useState(0)
  const [windowWidth, windowHeight] = useWindowSize({ wait: 100 })

  const paddingTop =
    (images.length > 0 && images[0].gatsbyImageData
      ? (images[0].gatsbyImageData.height / images[0].gatsbyImageData.width) *
      100
      : 50) + '%'

  return (
    <SliderContainer className="project-gallery-container">
      {/* {showImageLoader && (
    <Loading className="w-14 h-14 absolute-center z-10" />
  )} */}
      <Slider
        {...settings}
        beforeChange={(oldIndex, newIndex) => setSelectedIndex(newIndex)}
      >
        {images.map((props, i) => (
          <ImageGalleryImage
            key={i}
            maxHeight={maxHeight}
            windowHeight={windowHeight}
            windowWidth={windowWidth}
            pagePath={props.path}
            isFullScreen={isFullScreen}
            setMaxHeight={setMaxHeight}
            paddingTop={paddingTop}
            activeImageState={selectedIndex}
            {...props}
          />
        ))}
      </Slider>
    </SliderContainer>
  )
}

const ProjectPage = (props) => {
  const [lightboxIsOpen, setLightboxIsOpen] = useState(false)
  const activeImageState = useState(
    props.data.contentfulProjects &&
    props.data.contentfulProjects.photoGallery &&
    props.data.contentfulProjects.photoGallery.photos &&
    props.data.contentfulProjects.photoGallery.photos.map((_, i) => [
      i,
      false,
    ])
  )
  const [activeImageLoadingState, updateActiveImageLoadingState] =
    activeImageState
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [isFullScreen, setFullScreen] = useState(false)
  const [maxHeight, setMaxHeight] = useState(0)
  const [windowWidth, windowHeight] = useWindowSize({ wait: 100 })

  const toggleLightbox = (selectedIndex) => {
    setLightboxIsOpen(!lightboxIsOpen)
    setSelectedIndex(selectedIndex)
  }

  const { isLoading } = props

  const post = props.data.contentfulProjects
  const siteurl = props.data.site.siteMetadata.siteUrl
  const relatedPosts = []

  const { moreInCategory } = props.data

  const images =
    post &&
    post.photoGallery &&
    post.photoGallery.photos.map(
      ({ id, smallerFluid, file, fullscreen, title, ...rest }, i) => {
        return {
          i,
          id: i,
          caption: 'KYPD Kentucky Plumbing',
          src: fullscreen.src,
          w: 1200,
          h: 900,
          alt: title,
          thumbnail: smallerFluid.src,
          source: {
            original: file.url,
            regular: file.url,
            fullscreen: fullscreen.images.fallback.src,
          },
          file,
          smallerFluid,
          ...rest,
        }
      }
    )
  const items = images
  // const showImageLoader = !activeImageLoadingState[selectedIndex][1]

  const cancelButtonRef = useRef(null)


  return (
    <>

      <Layout pageProps={props}>
        <div className="bg-white border-b border-gray-100">
          <nav
            className="flex px-4 md:px-6 py-2  max-w-7xl mx-auto"
            aria-label="Breadcrumb"
          >
            <Breadcrumbs
              links={[
                { to: '/', label: 'Home' },
                { to: '/projects/', label: 'Projects' },
                {
                  to: props.location.pathname,
                  label: `${post.title} in ${post.city}, ${post.zipCode}`,
                },
              ]}
            />
          </nav>
        </div>
        <SEO
          title={
            post.metaTitle
              ? post.metaTitle
              : post.title +
              ' in ' +
              post.city +
              ', CA ' +
              post.zipCode +
              ' | KYPD Kentucky Plumbing'
          }
          description={
            post.metaDescription
              ? post.metaDescription
              : 'Check Out Photos of This Elegant ' +
              post.title +
              ' Project In ' +
              post.city +
              ' CA, ' +
              post.zipCode +
              ' Completed By KYPD Kentucky Plumbing.'
          }
          img={post.thumbnail.gatsbyImageData.images.fallback.src}
          twitterCardContent={'summary_large_image'}
        />
        <BlogPostLayout className="relative">
          <article itemScope itemType="https://schema.org/Article">
            <div
              itemProp="publisher"
              itemScope
              itemType="https://schema.org/Organization"
              className="hidden"
            >
              <span itemProp="name" className="hidden">
                KYPD Kentucky Plumbing
              </span>
              <span
                itemProp="image"
                itemScope
                itemType="http://schema.org/ImageObject"
                className="hidden"
              >
                <img
                  src="https://images.ctfassets.net/6c4iaavulnmy/TJqEt5ulPazetGtCkjwpm/3246ec7f0caede20b817352e0a811ea1/grimebusters-thumb3.jpg"
                  className="hidden"
                  width="262"
                  itemProp="url"
                />
              </span>
              <span
                itemProp="logo"
                itemScope
                itemType="http://schema.org/ImageObject"
                className="hidden"
              >
                <img
                  src="https://images.ctfassets.net/6c4iaavulnmy/TJqEt5ulPazetGtCkjwpm/3246ec7f0caede20b817352e0a811ea1/grimebusters-thumb3.jpg"
                  className="hidden"
                  width="262"
                  itemProp="url"
                />
              </span>
            </div>
            <Header>
              <div className="lg:flex px-4 md:px-6 py-12 max-w-7xl lg:row mx-auto">
                <div className='w-full md:w-2/3 md:pr-12'>
                  <h1 itemProp="name headline" className="text-left -mt-3 mb-2">
                    {post.title} in {post.city}, {post.zipCode}
                  </h1>
                  {post.description && (
                    <p
                      className="text-left text-base text-gray-700 mb-6"
                      dangerouslySetInnerHTML={{
                        __html: post.description.description,
                      }}
                    />
                  )}

                  <Button onClick={() => toggleLightbox(selectedIndex)} color='secondary' className='w-full shadow mb-4'
                  >Open Full Screen Gallery  <Maximize className="ml-2" size={20} />
                  </Button>

                  <div className="grid grid-cols-2 gap-4">
                    {images.map(props => (
                      <ImageGalleryImage
                        itemProp="image"
                        itemScope
                        itemType="http://schema.org/ImageObject"
                        maxHeight={maxHeight}
                        windowHeight={windowHeight}
                        windowWidth={windowWidth}
                        pagePath={props.path}
                        toggleLightbox={toggleLightbox}
                        isFullScreen={isFullScreen}
                        setMaxHeight={setMaxHeight}
                        postTitle={post.title}

                        // activeImageState={activeImageState}
                        {...props}
                      />
                    ))}
                  </div>

                  <div
                    className="mx-auto relative z-1 shadow bg-white p-8 rounded-xl mt-12"
                  >
                    <h3 className='text-lg mb-2'>Before and After Photos</h3>
                    <div className="grid gap-3 grid-cols-1">
                      {post.beforeAndAfter.length > 0 &&
                        post.beforeAndAfter.map(({ before, after }, i) => (
                          <div
                            className={`col-span-2 lg:col-span-1`}
                            key={i}
                          >
                            <ReactCompareImage
                              leftImage={before}
                              leftImageLabel={'Before'}
                              rightImage={after}
                              rightImageLabel={'After'}
                            />
                          </div>
                        ))}
                    </div>

                  </div>
                </div>
                <div className="w-full lg:w-1/3 mt-12 md:mt-0">

                  <div className="bg-white  rounded-xl p-8 pb-6 border-brand-500 md-mb-none shadow-xl relative ">

                    <span className='font-display text-sm text-orange-500  justify-center rounded-t-xl mb-6 bg-orange-100 px-8 py-3  -mt-8 -mx-8 flex items-center'> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className='text-orange-500 w-5 h-5 mr-1'><path fill="currentColor" d="M48 32H197.5C214.5 32 230.7 38.74 242.7 50.75L410.7 218.7C435.7 243.7 435.7 284.3 410.7 309.3L277.3 442.7C252.3 467.7 211.7 467.7 186.7 442.7L18.75 274.7C6.743 262.7 0 246.5 0 229.5V80C0 53.49 21.49 32 48 32L48 32zM112 176C129.7 176 144 161.7 144 144C144 126.3 129.7 112 112 112C94.33 112 80 126.3 80 144C80 161.7 94.33 176 112 176z" /><path fill="currentColor" className="opacity-50" d="M311.1 38.93C320.5 29.61 335.7 29.7 344.1 39.13L472.8 168.4C525.1 221.4 525.1 306.6 472.8 359.6L360.8 472.9C351.5 482.3 336.3 482.4 326.9 473.1C317.4 463.8 317.4 448.6 326.7 439.1L438.6 325.9C472.5 291.6 472.5 236.4 438.6 202.1L310.9 72.87C301.5 63.44 301.6 48.25 311.1 38.93V38.93z" /></svg>
                      Online Special</span>
                    <div className="mb-4">
                      <h3 className="font-display leading-6 md:leading-7 font-bold text-2xl text-gray-800 mb-2">
                        Need Help With Your Project?
                      </h3>
                      <div className="  text-brand-400 mb-2 leading-6 mt-1 flex items-start"><svg class="mr-2   mt-1 h-6 w-6 flex-shrink-0 text-brand-400 " fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75m-3-7.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285z"></path>
                      </svg>Reach Out To Us Today!</div>

                    </div>
                    <ContactForm hideLabels={true} showPlaceholders={true} footerContained={true} />

                  </div>



                </div>
              </div>
            </Header>
          </article>
        </BlogPostLayout>

        {moreInCategory &&
          moreInCategory.edges &&
          moreInCategory.edges.length > 0 ? (
          <div className="  px-4 md:px-6 py-12 max-w-7xl mx-auto">
            <h3 className='mb-2'>
              Check out some of our other {post.categories[0].name} projects
            </h3>
            <div className="grid grid-cols-2 gap-4 lg:grid-cols-4">
              {moreInCategory &&
                moreInCategory.edges &&
                moreInCategory.edges.map((item, i) => (
                  <Link
                    key={i}
                    to={`/projects/${item.node.slug}/`}
                    className="bg-white shadow hover:shadow-lg rounded-lg"
                  >
                    <GatsbyImage
                      image={getImage(item.node.heroImage)}
                      style={{ height: '150px' }}
                      alt={item.node.title}
                    />
                    <div className="p-3 py-4 pt-3">
                      <span className="text-gray-700 text-base">
                        {item.node.publishDate}
                      </span>
                      <span className=" font-semibold leading-tight text-gray-900 block">
                        {item.node.title}
                      </span>
                      <div className="flex items-center text-gray-800 text-xs mt-1">
                        <div className="">
                          <img
                            src="/assets/location-pin.svg"
                            className="w-2 mb-0"
                          />
                        </div>
                        <div className="ml-2 text-xs">
                          {item.node.city}, OR {item.node.zipCode}
                        </div>
                      </div>
                    </div>
                  </Link>
                ))}
            </div>
          </div>
        ) : null}
      </Layout>

      <Transition.Root show={lightboxIsOpen} as={Fragment}>
        <Dialog as="div" className="relative z-20" initialFocus={cancelButtonRef} onClose={() => toggleLightbox(selectedIndex)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black  bg-opacity-[90%] transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-50 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="z-50 relative transform    text-left  transition-all   sm:max-w-4xl ">
                  <button className='bg-white absolute -top-4 right-0 p-2 z-50 bg-white/20  text-white font-display text-sm flex items-center space-x-2 rounded-2xl' onClick={() => toggleLightbox(selectedIndex)}><X className='h-8 w-8' /></button>
                  <Carousel
                    currentIndex={selectedIndex}
                    frameProps={{ autoSize: '80vh' }}
                    styles={{
                      view: (base, state) => ({
                        ...base,
                        margin: '0 auto',
                        height: '80vh',
                        display: 'flex!important',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '12',

                      }),
                    }}
                    views={items}
                  />


                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

    </>
  )
}

export const pageQuery = graphql`
  query ProjectPage($slug: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    contentfulProjects(slug: { eq: $slug })  {
      id
      title
      slug
      categories: category {
        id
        name
        slug
      }
      date
      city
      zipCode
      thumbnail {
        gatsbyImageData(width: 1500, quality: 70)
      }
      heroImage: thumbnail {
        gatsbyImageData(width: 1500, quality: 70)
      }
      beforeAndAfter {
        id
        before {
          id
          gatsbyImageData(width: 1000, quality: 70, placeholder: NONE)
        }
        after {
          id
          gatsbyImageData(width: 1000, quality: 70, placeholder: NONE)
        }
      }
      photoGallery {
        id
        photos {
          id
          smallerFluid: gatsbyImageData(
            quality: 70
            placeholder: NONE
            height: 1000
          )
          fullscreen: gatsbyImageData(quality: 80, width: 1874)
          file {
            url
          }
          gatsbyImageData(quality: 70, width: 1000, placeholder: NONE)
        }
      }
      description {
        id
        description
      }
      videoTestimonialRef {
        id
        thumbnail {
          gatsbyImageData(width: 1000, quality: 70, placeholder: NONE)
        }
        video {
          file {
            url
            contentType
          }
        }
      }
      review  {
        id
        source
        date
        reviewTitle
        reviewer
        source
        date
        category {
          id
          name
          slug
        }
        reviewBody {
          id
          reviewBody
        }
      }
    }
    moreInCategory: allContentfulProjects(
      filter: { 
        slug: { ne: $slug }
      }
      limit: 4
      sort: { order: DESC, fields: date }
    ) {
      edges {
        node {
          id
          title
          slug
          date
          city
          zipCode
          heroImage: thumbnail {
            gatsbyImageData(width: 1200, quality: 70, placeholder: NONE)
          }
        }
      }
    }
  }
`

export default ProjectPage
